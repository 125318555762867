import { useEffect, useState } from "react";

function useDeviceDetect() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof window !== "undefined" ? navigator.userAgent : "";
    const mobileDevices =
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
    setIsMobile(mobileDevices.test(userAgent));
  }, []);

  return { isMobile };
}

export default useDeviceDetect;
