import { toast } from "react-toastify";
import { PaymentServices } from "../services/paymentService";
import { load } from "@cashfreepayments/cashfree-js";

const APP_RETURN_URL = process.env.REACT_APP_RETURN_URL || "";

export type PaymentSuccessful = {
  redirect: boolean;
  error: {
    message: String;
  };
};
export type Pay = {
  cashfree: any;
  paymentMethod: any;
  paymentSessionId: String;
  redirectTarget: String;
  returnUrl: String;
};

function pay(data: Pay): void {
  try {
    data.cashfree
      .pay({
        paymentMethod: data.paymentMethod,
        paymentSessionId: data.paymentSessionId,
        redirectTarget: data.redirectTarget,
        returnUrl: data.returnUrl,
      })
      .then(function (data: PaymentSuccessful) {
        if (data.error) {
          toast.error(data.error.message);
        }
      });
  } catch (error) {
    throw new Error("Method not implemented.");
  }
}

export async function mountCard(
  setPaymentButtonStatus: any,
  setCardComponent: any,
) {
  const cashfree = await load({
    mode: process.env.REACT_APP_CASHFREE_MODE,
  });

  let card = cashfree.create("cardNumber", {
    values: {
      placeholder: "enter card number",
    },
    classes: {
      base: "my-base",
      complete: "my-complete",
    },
    style: {
      base: {
        color: "#FFFFFF",
      },
    },
  });
  setCardComponent(card);
  card.mount("#cardNumber");

  let cardHolder = cashfree.create("cardHolder", {
    values: {
      placeholder: "enter card holder name",
      cardHolder: "",
    },
    classes: {
      base: "my-base",
      complete: "my-complete",
    },
    style: {
      base: {
        color: "#fff",
      },
    },
  });

  cardHolder.mount("#cardHolder");

  let cardExpiry = cashfree.create("cardExpiry", {
    classes: {
      base: "my-expiry",
      complete: "my-expiry-complete",
    },
    style: {
      base: {
        color: "#fff",
      },
    },
  });

  cardExpiry.mount("#expiry");

  let cardCvv = cashfree.create("cardCvv", {
    values: {
      placeholder: "enter card cvv",
    },
    style: {
      base: {
        textAlign: "right",
        padding: "10px",
        color: "#fff",
      },
    },
  });

  cardCvv.mount("#cvv");

  function toggleBtn() {
    if (
      cardExpiry.isComplete() &&
      cardHolder.isComplete() &&
      card.isComplete() &&
      cardCvv.isComplete()
    ) {
      setPaymentButtonStatus(false);
    } else {
      setPaymentButtonStatus(true);
    }
  }

  card.on("change", function () {
    toggleBtn();
  });
  cardHolder.on("change", function () {
    toggleBtn();
  });
  cardExpiry.on("change", function () {
    toggleBtn();
  });
  cardCvv.on("change", function () {
    toggleBtn();
  });

  return cashfree;
}

export class Cashfree implements PaymentServices {
  async initiateUpi(Upi: {
    payment_id: String;
    upi_id: string;
    provider: String;
    collect_request_id: String;
    providerId?: String;
    method?: String;
    amount?: number;
    isBlank?: string;
  }): Promise<String> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      const upiApp = cashfree.create("upiApp", {
        values: {
          upiApp: Upi.provider,
          buttonText: "Paytm",
          buttonIcon: true,
        },
        style: {
          base: {
            color: "#717171",
            fontSize: "14px",
            display: "flex",
            justifyContent: "center",
          },
        },
      });
      Upi.upi_id = Upi.providerId
        ? Upi.upi_id.split("@")[0] + Upi.providerId
        : Upi.upi_id;
      const upiCollect = cashfree.create("upiCollect", {
        values: {
          upiId: Upi.upi_id,
          buttonText: "Pay",
        },
        style: {
          base: {
            fontSize: "22px",
            display: "none",
          },
        },
      });
      upiApp.mount("#mounting-point");
      upiApp.on("ready", async function () {
        pay({
          cashfree,
          paymentMethod: upiApp,
          paymentSessionId: Upi.payment_id,
          redirectTarget: Upi.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + Upi.collect_request_id,
        });
      });
      upiCollect.mount("#mounting-point");
      upiCollect.on("ready", async function () {
        pay({
          cashfree,
          paymentMethod: upiCollect,
          paymentSessionId: Upi.payment_id,
          redirectTarget: Upi.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + Upi.collect_request_id,
        });
      });
      return "";
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  async initiateNetBanking(Netbaking: {
    bankCode: String;
    payment_id: String;
    collect_request_id: String;
    method: String;
    amount: number;
    isBlank?: string;
  }): Promise<String> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      const netbanking = cashfree.create("netbanking", {
        values: {
          netbankingBankName: Netbaking.bankCode,
          buttonText: `${Netbaking.bankCode} Bank`,
          buttonIcon: true,
        },
        classes: {
          complete: "empty_div",
        },
      });
      netbanking.mount("#netbanking");
      netbanking.on("ready", function () {
        pay({
          cashfree,
          paymentMethod: netbanking,
          paymentSessionId: Netbaking.payment_id,
          redirectTarget: Netbaking.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + Netbaking.collect_request_id,
        });
      });

      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method no t implemented.");
    }
  }
  async initiateWallet(Wallet: {
    walletName: String;
    phoneNumber: String;
    payment_id: String;
    collect_request_id: String;
    amount: number;
    method: String;
    isBlank?: string;
  }): Promise<String> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      let component = cashfree.create("wallet", {
        values: {
          provider: Wallet.walletName,
          phone: Wallet.phoneNumber,
          buttonIcon: false,
        },
        style: {
          base: {
            fontSize: "22px",
            display: "none",
          },
        },
      });
      component.mount("#" + Wallet.walletName);

      component.on("ready", async function () {
        pay({
          cashfree,
          paymentMethod: component,
          paymentSessionId: Wallet.payment_id,
          redirectTarget: Wallet.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + Wallet.collect_request_id,
        });
      });
      return "";
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  async initiatePaylater(Paylater: {
    payLaterServices: String;
    phoneNumber: String;
    payment_id: String;
    collect_request_id: String;
    amount: number;
    method: String;
    isBlank?: string;
  }): Promise<String> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      const payLater = cashfree.create("paylater", {
        values: {
          provider: Paylater.payLaterServices,
          phone: Paylater.phoneNumber,
          buttonIcon: false,
        },
        classes: {
          complete: "empty_div",
        },
      });
      payLater.mount("#pay-later-mount-point");
      payLater.on("ready", function () {
        pay({
          cashfree,
          paymentMethod: payLater,
          paymentSessionId: Paylater.payment_id,
          redirectTarget: Paylater.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + Paylater.collect_request_id,
        });
      });

      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  async initiateCardlessEmi(CardLessEmi: {
    providerName: String;
    phoneNumber: String;
    payment_id: String;
    collect_request_id: String;
    amount: number;
    method: String;
    isBlank?: string;
  }): Promise<String> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      const cardLessEmi = cashfree.create("cardlessEMI", {
        values: {
          provider: CardLessEmi.providerName,
          phone: CardLessEmi.phoneNumber,
          buttonText: CardLessEmi.providerName,
          buttonIcon: true,
        },
        classes: {
          complete: "empty_div",
        },
      });
      cardLessEmi.mount("#" + CardLessEmi.providerName);
      cardLessEmi.on("ready", function () {
        pay({
          cashfree,
          paymentMethod: cardLessEmi,
          paymentSessionId: CardLessEmi.payment_id,
          redirectTarget: CardLessEmi.isBlank === "true" ? "_blank" : "_self",
          returnUrl: APP_RETURN_URL + CardLessEmi.collect_request_id,
        });
      });

      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  initiateCard(Card: {
    cashfree: any;
    payment_id: String;
    collect_request_id: String;
    component: any;
    cardNumber?: String;
    CVV?: String;
    cardHolderName?: String;
    expireDate?: String;
    amount?: number;
    method?: String;
    isBlank?: string;
  }): Promise<String> {
    try {
      pay({
        cashfree: Card.cashfree,
        paymentMethod: Card.component,
        paymentSessionId: Card.payment_id,
        redirectTarget: Card.isBlank === "true" ? "_blank" : "_self",
        returnUrl: APP_RETURN_URL + Card.collect_request_id,
      });
      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  async getQrCode(QrCode: {
    qrRef: React.RefObject<HTMLDivElement> | null;
    paymentId: String;
    collect_request_id: String;
  }): Promise<void> {
    try {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      const qr = cashfree.create("upiQr", {
        values: {
          size: "157px",
        },
      });
      qr.mount(QrCode.qrRef);
      qr.on("ready", async function () {
        pay({
          cashfree,
          paymentMethod: qr,
          paymentSessionId: QrCode.paymentId,
          redirectTarget: "_self",
          returnUrl: APP_RETURN_URL + QrCode.collect_request_id,
        });
      });
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
}
