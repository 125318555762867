import React, { useEffect, useState } from "react";
import cardType from "../../assets/creditCard.svg";
import chip from "../../assets/Chip.svg";
import connectIcon from "../../assets/Conection Icon.svg";
import creditCard from "../../assets/creditCard.svg";
// import connectIcon from "../../assets/Conection Icon.svg";
import { load } from "@cashfreepayments/cashfree-js";
import "./Card.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Cashfree, mountCard } from "../../payment_gatways/cashfree";
import { Easebuzz } from "../../payment_gatways/easebuzz";
import axios from "axios";
import creditCardType from "credit-card-type";
import amex from "../../assets/cardLogo/amex.png";
import master from "../../assets/cardLogo/master.png";
import rupay from "../../assets/cardLogo/rupay.png";
import visa from "../../assets/cardLogo/visa.png";
function Card({
  finalAmountWithMDR,
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  cardType,
  isBlank,
  geteway,
  easebuzzPaymentId,
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [cashfree1, setCashfree] = useState(null);
  const [cardComponent, setCardComponent] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvvNumber, setCardCvvNumber] = useState("");
  const [cardExpiryDate, setCardExpiryDate] = useState("");
  const [card_type, set_card_type] = useState({
    type: "",
    img: "",
  });

  const cashfree = new Cashfree();
  const easebuzz = new Easebuzz();

  const [paymentButtonStatus, setPaymentButtonStatus] = useState(false);
  useEffect(() => {
    if (geteway === "CASHFREE") {
      const loadCashfree = async () => {
        const cash = await mountCard(setPaymentButtonStatus, setCardComponent);
        setCashfree(cash);
      };
      loadCashfree();
    }
  }, [geteway]);
  const getCardType = (number) => {
    const cardNumber = number.replace(/\D/g, "");
    const patterns = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      masterCard: /^5[1-5][0-9]{14}$/,
      ruPay: /^6[0-9]{15}$/,
      amex: /^3[47][0-9]{13}$/,
      maestro: /^(?:5[06789][0-9]{0,}|6[0-9]{0,})[0-9]{12,}$/,
    };

    if (patterns.visa.test(cardNumber)) return "Visa";
    if (patterns.masterCard.test(cardNumber)) return "MasterCard";
    if (patterns.ruPay.test(cardNumber)) return "RuPay";
    return "Unknown Card Type";
  };

  const payamount = async () => {
    if (geteway === "EASEBUZZ") {
      axios
        .get(
          `https://payments.edviron.com/easebuzz/encrypted-info?card_number=${cardNumber}&card_holder=${cardHolderName}&card_cvv=${cardCvvNumber}&card_exp=${`${cardExpiryDate.slice(0, 3)}20${cardExpiryDate.slice(3)}`}`
        )
        .then((response) => {
          if (response.data) {
            easebuzz.initiateCard({
              payment_id: easebuzzPaymentId,
              card_number: response.data.card_number,
              card_holder_name: response.data.card_holder,
              card_cvv: response.data.card_cvv,
              card_expiry_date: response.data.card_exp,
              type:
                cardType === "DebitCard"
                  ? "DC"
                  : cardType === "CreditCard"
                    ? "CC"
                    : "",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error.message);
        });
    }
    if (geteway === "CASHFREE") {
      cashfree.initiateCard({
        cashfree: cashfree1,
        payment_id: paymentId,
        collect_request_id: collect_request_id,
        component: cardComponent,
        isBlank,
      });
    }
  };

  useEffect(() => {
    payRef.current.disabled = true;

    if (paymentButtonStatus === false && geteway === "CASHFREE") {
      payRef.current.disabled = false;
    }
    if (
      geteway === "EASEBUZZ" &&
      cardNumber !== "" &&
      cardHolderName !== "" &&
      cardCvvNumber !== "" &&
      cardCvvNumber.length === 3
    ) {
      payRef.current.disabled = false;
    }
    if (cardNumber.length > 15) {
      finalAmountWithMDR(cardType, card_type?.type);
    }
  }, [paymentButtonStatus, cardNumber, cardHolderName, cardCvvNumber]);

  childComponentFunctionRef.current = payamount;
  // childComponentFunctionRef.current = () => {
  //   easebuzz.initiateCard({
  //     payment_id: easebuzzPaymentId,
  //   });
  // };

  // if (isBuzz === "true") {
  //   return <div></div>;
  // }

  return (
    <>
      <div className="flex items-center mt-8 pl-7 space-x-4">
        <div className="flex justify-between px-3  items-center w-22 h-10 shadow-black-shadow rounded-lg">
          <img src={creditCard} className="w-[39px] h-[29px]" alt="cards" />
          <p className="ml-1 mt-1 font-medium text-[7px] text-[#717171]">
            <span>Credit</span> <br />
            <span>Card</span>
          </p>
        </div>
        <div className="flex flex-col">
          <p className=" text-base text-black font-semibold ">
            Pay with {cardType === "CreditCard" ? "Credit Card" : "Debit Card"}{" "}
          </p>
          <p className=" text-xs font-normal text-[#717171]">
            Enter Card details
          </p>
        </div>
      </div>
      {/*<div className="px-4 flex mb-3 justify-end items-center gap-2">
        <h6 className="text-xs">Flip to enter CVV</h6>
        <span>
          <i
            onClick={() => {
              setIsFlipped(!isFlipped);
            }}
            className="fa-solid text-xl cursor-pointer fa-repeat ml-auto"
          >
            {" "}
          </i>
        </span>
          </div>*/}
      <div
        className={`credit-card w-full mt-4 h-64 ${isFlipped ? "flipped" : ""}`}
      >
        <div className="front px-4 text-white">
          {geteway === "CASHFREE" && (
            <div
              className={
                "flex h-60 justify-between  flex-col bg-gradient-to-r from-neutral-600 to-zinc-900 p-[30px] rounded-xl"
              }
            >
              <div className="w-full flex mb-3 gap-x-2 items-center">
                <img src={chip} className="" alt="cardType" />
                <img src={connectIcon} className="" alt="cardType" />
              </div>
              <div id="cardNumber"></div>
              <div className="flex gap-x-10 items-center justify-sapcebetween">
                <div id="cardHolder"></div>
                <div className="shrink-0" id="expiry"></div>
              </div>

              <div className="flex justify-center items-center relative">
                <div className="max-w-fit">
                  <p className="text-[16px] text-[#717171]">CVV</p>
                </div>
                <div id="cvv" className="w-10"></div>
              </div>
            </div>
          )}
          {geteway === "EASEBUZZ" && (
            <div className="h-60 w-96 rounded-lg bg-gradient-to-r from-neutral-600 to-zinc-900 p-[30px] text-white shadow-lg">
              <div className="mb-3 flex w-full items-center gap-x-2">
                <img
                  src="https://pg.edviron.com/static/media/Chip.fa79a9f20a676e2745b94773b7c0caeb.svg"
                  className=""
                  alt="cardType"
                />
                <img
                  src="https://pg.edviron.com/static/media/Conection%20Icon.f7fcdf1f04fe3f752f9fea9126e4fa13.svg"
                  className=""
                  alt="cardType"
                />
              </div>

              <div className="mb-4 border-b flex  justify-between items-center">
                <input
                  type="text"
                  value={cardNumber}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, "");
                    let formatted = "";
                    for (let i = 0; i < input.length; i++) {
                      if (i > 0 && i % 4 === 0) {
                        formatted += " ";
                      }
                      formatted += input[i];
                    }
                    formatted = formatted.slice(0, 19);
                    setCardNumber(formatted);
                    const type = getCardType(input);

                    set_card_type({
                      type: type,
                      img:
                        type === "RuPay"
                          ? rupay
                          : type === "Visa"
                            ? visa
                            : type === "MasterCard"
                              ? master
                              : null,
                    });
                  }}
                  id="card-number"
                  pattern="[0-9]*"
                  maxLength="19"
                  placeholder="enter card number"
                  className="w-full  bg-transparent py-1.5 focus:outline-none"
                />

                {card_type?.img && (
                  <img
                    className="h-5"
                    src={card_type?.img}
                    alt={card_type?.type}
                  />
                )}
              </div>
              <div className="mb-4 flex items-center justify-between">
                <div className="mr-2 w-full">
                  <input
                    type="text"
                    id="card-holder"
                    onChange={(e) => {
                      setCardHolderName(e.target.value);
                    }}
                    placeholder="enter card holder name"
                    className="w-full border-b bg-transparent py-1.5 focus:outline-none"
                  />
                </div>
                <div className="ml-2 w-1/3">
                  <input
                    type="text"
                    id="expiry-date"
                    placeholder="MM/YY"
                    value={cardExpiryDate}
                    onChange={(e) => {
                      let input = e.target.value.replace(/\D/g, "");
                      let formatted = "";
                      for (let i = 0; i < input.length; i++) {
                        if (input.length === 2 && Number(input) > 12) {
                          input = "12";
                        }
                        if (i > 0 && i % 2 === 0) {
                          formatted += "/";
                        }
                        formatted += input[i];
                      }
                      formatted = formatted.slice(0, 6);
                      setCardExpiryDate(formatted);
                    }}
                    className={`w-full border-b bg-transparent py-1.5 focus:outline-none 
                    ${
                      cardExpiryDate.length > 2 &&
                      cardExpiryDate.slice(3) <
                        new Date().getFullYear().toString().slice(2)
                        ? " border-red-500 text-red-500"
                        : " border-white text-white"
                    }
                      
                  `}
                    inputmode="numeric"
                    pattern="[0-9]*"
                    maxLength="5"
                  />
                </div>
              </div>
              <div className="mb-4 flex items-center justify-center space-x-3">
                <p className="text-[16px] text-[#717171]">CVV</p>
                <input
                  value={cardCvvNumber}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, "");
                    setCardCvvNumber(input);
                  }}
                  type="password"
                  id="numberInput"
                  maxLength="3"
                  placeholder="•••"
                  className="w-1/3 bg-transparent py-1.5 focus:outline-none"
                  inputmode="numeric"
                  pattern="[0-9]*"
                />
              </div>
            </div>
          )}
        </div>

        {/*<div className="back px-4 relative">
      <p className=" absolute transform text-[4px] text-white font-normal right-0 bottom-28 -rotate-90">
        CIA 000012 CSM-1234--4321
      </p>
      <div
        className={
          " h-full bg-gradient-to-r from-neutral-600 to-zinc-900 pt-4 rounded-xl"
        }
      >
        <div className="h-10 bg-black">dsf</div>
        <div className="text-[6px] text-white font-normal ml-8 mt-6 max-w-[17rem] flex justify-between">
          <span>Authorized Signature</span>
          <span>Not Valid Unless Signed</span>
        </div>
        <div className="flex bg-[#E5E5E5] justify-between h-10 ml-8 max-w-[17rem]">
          <div id="cvv" className="text-left grid content-center"></div>
        </div>
        <div className=" max-w-[16rem] mt-4">
          <p className="text-[5px] text-justify ml-8 text-white font-normal">
            Lorem Ipsum Indoctum accusamus comprehensam Nullam id dolor id
            nibh ultricies vehicula ut id elit. Donec sed odio dui. Fusce
            dapibus, tellus ac cursus etiam porta sem malesuada magna mollis
            euismod. commodo, Faccibus mollis interdum. Morbi leo risus,
            porta ac, vestibulum at eros.Feugiat accumsan Suspendisse eget
            Duis faucibus tempus pede pede augue pede. Dapibus mollis
            dignissim suscipit porta justo nisl amet Nunc quis semper.
            Indoctum accusamus comprehensam .
          </p>
        </div>
        <div className="mt-6 space-y-3 text-center">
          <p className="text-[5px] text-white font-normal">
            +234089876543, +234803456789, +234089876543, +234089876543,
          </p>
          <p className="text-[5px] text-white font-normal">
            www.bankofruqmania.com
          </p>
        </div>
      </div>
      </div>*/}
      </div>

      {/* <button onClick={payamount}>Pay</button> */}
    </>
  );
}
export default Card;
