import React, { useEffect, useState, useRef } from "react";
import sbiBank from "../../assets/sbi.svg";
import hdfcBank from "../../assets/hdfc.svg";
import axis from "../../assets/axisabnk.svg";
import { load } from "@cashfreepayments/cashfree-js";
import {
  Banks,
  defaultEasebuzzBanks,
  EasebuzzBanks,
} from "../../utils/bankData";
import Select from "react-select";
import { toast } from "react-toastify";
import { Cashfree } from "../../payment_gatways/cashfree";
import { Easebuzz } from "../../payment_gatways/easebuzz";
import { GET_PAYMENT_GATEWAY } from "../../services/getPaymentGateway";

function NetBanking({
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  finalAmountWithMDR,
  isBlank,
  isBuzz,
  easebuzzPaymentId,
}) {
  const selectRef = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [selectedBank, setSelectedBank] = useState("");
  const [bankname, setbankname] = useState("");

  const [gateway, setGateway] = useState("");

  useEffect(() => {
    payRef.current.disabled = true;
    if (selectedBank !== "") payRef.current.disabled = false;
  }, [selectedBank]);

  // const selectedBanks = [
  //   "ICICI Bank",
  //   "Axis Bank",
  //   "Yes Bank Ltd",
  //   "State Bank Of India",
  // ];
  const handleBankChange = async (event) => {
    const gatewayName = await GET_PAYMENT_GATEWAY(event.enum, "net_banking");
    setGateway(gatewayName);
    if (gatewayName === "CASHFREE") {
      if (gatewayName === "CASHFREE" && event.cf_code && event.eb_code) {
        setSelectedBank(event.cf_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      } else if (
        gatewayName === "CASHFREE" &&
        event.cf_code !== null &&
        event.eb_code === null
      ) {
        setSelectedBank(event.cf_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      } else if (
        gatewayName === "CASHFREE" &&
        event.eb_code !== null &&
        event.cf_code === null
      ) {
        setSelectedBank(event.eb_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      }
    }

    if (gatewayName === "EASEBUZZ") {
      if (gatewayName === "EASEBUZZ" && event.cf_code && event.eb_code) {
        setSelectedBank(event.eb_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      } else if (
        gatewayName === "EASEBUZZ" &&
        event.cf_code !== null &&
        event.eb_code === null
      ) {
        setSelectedBank(event.cf_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      } else if (
        gatewayName === "EASEBUZZ" &&
        event.eb_code !== null &&
        event.cf_code === null
      ) {
        setSelectedBank(event.eb_code);
        setbankname(event.label);
        finalAmountWithMDR("netbanking", event.label);
      }
    }
  };

  const options = Banks.map((bank) => {
    return {
      label: bank.name,
      cf_code: bank.cf_code,
      eb_code: bank.eb_code,
      enum: bank.enumName,
    };
  });

  const cashfree = new Cashfree();
  const easebuzz = new Easebuzz();

  const handleSubmit = () => {
    if (gateway === "CASHFREE") {
      cashfree.initiateNetBanking({
        bankCode: selectedBank,
        payment_id: paymentId,
        collect_request_id,
        isBlank,
      });
    }
    if (gateway === "EASEBUZZ") {
      easebuzz.initiateNetBanking({
        bankCode: selectedBank,
        payment_id: easebuzzPaymentId,
      });
    }
  };
  const handlebankClick = (bankName, mountPoint) => {
    cashfree.initiateNetBanking({
      bankCode: bankName,
      payment_id: paymentId,
      collect_request_id,
    });
  };
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
    setSelectedBank("");
    setbankname("");
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  childComponentFunctionRef.current = handleSubmit;
  return (
    <div>
      <div>
        <div>
          <div className="flex p-4 gap-x-4">
            <div className="w-auto px-3 h-[50px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg">
              <div className="flex text-[#717171] items-center">
                <i className="fa-solid text-lg fa-building-columns"></i>
                <h6 className=" text-sm  font-medium ml-2">All Banks</h6>
              </div>
            </div>
            <div className="flex flex-col justify-center items-start m-1 ">
              <p className="text-base text-black font-semibold">Net banking</p>
              <p className="text-[12px] text-[#717171]">
                Enter Bank Account details{" "}
              </p>
            </div>
          </div>
          <div className="p-4 pb-0 h-auto max-h-96">
            <div className="flex flex-col w-full h-full">
              <label
                className="font-semibold text-[16xp] pl-2"
                htmlFor="bankDropdown"
              >
                Choose bank:
              </label>
              <Select
                className=" font-normal p-2 rounded-lg"
                id="bankDropdown"
                //ref={selectRef}
                value={selectedBank}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                onChange={handleBankChange}
                options={options}
                isSearchable
                placeholder={bankname ? bankname : "Search bank"}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#E8EBF6",
                    border: "none",
                  }),
                  input: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    "::placeholder": {
                      opacity: 1,
                    },
                  }),
                }}
              />
            </div>
          </div>
          {!selectedBank && (
            <div
              id="default"
              className={`flex gap-x-4 py-4 overflow-hidden overflow-x-scroll scrollbar-hide ${
                menuIsOpen ? "h-80" : "h-auto"
              }  pl-7`}
            >
              <div
                //onClick={() => handlebankClick("SBINR", "mountsbi")}
                className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img className="w-6 mr-2" src={sbiBank} alt="SBI" />
                <h6 className=" text-xs text-[#717171] font-medium">SBI</h6>
              </div>
              <div
                id="mounthdfc"
                // onClick={() => {
                //   if (isBuzz === "true") {
                //     bankPay("HDFCB");
                //   } else {
                //     handlebankClick("HDFCR", "mounthdfc");
                //   }
                // }}
                className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img className="w-6 mr-2" src={hdfcBank} alt="HDFC" />
                <h6 className=" text-xs text-[#717171] font-medium">HDFC</h6>
              </div>

              {/*<div>
                <button id="mountsbi">
                  <img
                    className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                    alt="SBI"
                  />
                </button>
              </div>*/}
              {/*<div>
                <div className="flex items-center h-full w-full p-0">
                  <button id="mounthdfc">
                    <img
                      className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                      src={hdfcBank}
                      alt="HDFC"
                    />
                  </button>
                </div>
              </div>*/}
              {/*<label htmlFor="bankDropdown">
              <div
                onClick={() => handlebankClick("UTIBR", "mountaxis")}
                className="h-[40px] min-w-[140px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img className="p-3" src={axis} alt="axis" />
              </div>
              <div>
                <div

                >
                  <button>
                    <img
                      className="w-[140px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
                      src={other}
                      alt="HDFC"
                    />
                  </button>
                </div>
              </div>
            </label>*/}
            </div>
          )}
        </div>
        <div id="netbanking"></div>
      </div>
    </div>
  );
}
export default NetBanking;
