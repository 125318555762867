import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import { load } from "@cashfreepayments/cashfree-js";
import gPay from "../../assets/googlePay.png";
import paytm from "../../assets/paytmSvg.svg";
import phonepe from "../../assets/phonepe.png";
import upi from "../../assets/upi.png";
import Select from "react-select";

import { Cashfree } from "../../payment_gatways/cashfree";
import { Easebuzz } from "../../payment_gatways/easebuzz";
import { GET_PAYMENT_GATEWAY } from "../../services/getPaymentGateway";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";
import useDeviceDetect from "../../hooks/useDeviceDetect";

const Upi = ({
  paymentId,
  payButton,
  childComponentFunctionRef,
  payRef,
  collect_request_id,
  provider,
  prov_id,
  finalAmountWithMDR,
  isBlank,
  setUpiProvider,
  setUpiProviderId,
  isBuzz,
  easebuzzPaymentId,
  geteway,
}) => {
  const [vpaId, setVpaId] = useState("");
  // console.log(geteway);

  const mountingRef = useRef(null);
  const vpainputRef = useRef(null);

  const [providerId, setProviderId] = useState(prov_id);
  const gpayRef = useRef(null);
  const paytmRef = useRef(null);
  const phonepeRef = useRef(null);
  const otherRef = useRef(null);

  const cashfree = new Cashfree();
  const easebuzz = new Easebuzz();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [phonePeIntent, setphonePeintent] = useState("");
  const [goglePayIntent, setgoglePayIntent] = useState("");
  const [paytmIntent, setpaytmIntent] = useState("");
  const { isMobile } = useDeviceDetect();
  const getIntent = async () => {
    try {
      const intents = await axios.get(
        `${process.env.REACT_APP_PAYMENT_BACKEND}/easebuzz/upiqr?collect_id=${collect_request_id}`
      );
      setphonePeintent(intents.data.phonePe);
      setgoglePayIntent(intents.data.googlePe);
      setpaytmIntent(intents.data.paytm);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getIntent();
  }, [geteway]);

  const upiOptions = {
    paytm: ["@paytm", "@pthdfc", "@ptsbi", "@ptyes", "@ptaxis"],
    gpay: ["@okicici", "@okaxis", "@oksbi", "@okhdfcbank"],
    phonepe: ["@axl", "@ybl", "@ibl"],
  };
  const icons = {
    paytm: paytm,
    gpay: gPay,
    phonepe: phonepe,
  };

  const name = {
    paytm: "Paytm",
    gpay: "Google Pay",
    phonepe: "PhonePe",
    other: "Other",
  };
  const reference = {
    paytm: paytmRef,
    gpay: gpayRef,
    phonepe: phonepeRef,
    other: otherRef,
  };

  const isValidUpiId = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/.test(vpaId);

  useEffect(() => {
    setProviderId(prov_id);
  }, [prov_id]);

  useEffect(() => {
    finalAmountWithMDR("upi", "Others");
  }, []);

  useEffect(() => {
    if (provider !== "other") {
      payRef.current.disabled = vpaId.length >= 3 ? false : true;
    } else {
      payRef.current.disabled = isValidUpiId ? false : true;
    }
  }, [vpaId]);

  childComponentFunctionRef.current = () => {
    if (geteway !== "CASHFREE") {
      const upiId = providerId ? vpaId.split("@")[0] + providerId : vpaId;
      easebuzz.initiateUpi({
        payment_id: easebuzzPaymentId,
        upi_id: upiId,
      });
    } else {
      cashfree.initiateUpi({
        payment_id: paymentId,
        upi_id: vpaId,
        provider: provider,
        collect_request_id: collect_request_id,
        providerId: providerId,
        isBlank,
      });
    }
  };
  const handleRedirect = (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue);
    // setUpiProvider(selectedValue);

    let url = "";

    // Map the selected value to the respective URL
    switch (selectedValue) {
      case "phonePe":
        url = phonePeIntent;
        break;
      case "googlePay":
        url = goglePayIntent;
        break;
      case "paytm":
        url = paytmIntent;
        break;
      default:
        break;
    }

    // Set the intent URL and redirect
    // setIntentUrl(url);
    if (url) {
      window.location.href = url; // Redirect to the selected URL
    }
  };
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  return (
    <>
      <div className={"p-4 pb-0 " + (menuIsOpen ? "h-80" : "h-auto")}>
        <div className="flex flex-col w-full h-full">
          <label
            className="font-semibold text-[16xp] pl-2"
            htmlFor="bankDropdown"
          >
            Choose UPI:
          </label>

          {geteway !== "CASHFREE" && isMobile === true ? (
            <div className="border flex mt-2 justify-between items-center rounded-lg px-3 py-2 ">
              <select
                onChange={handleRedirect}
                className=" appearance-none flex-1 bg-transparent focus:outline-none"
              >
                <option value="">Select Payment Method</option>
                <option value="phonePe">PhonePe</option>
                <option value="googlePay">Google Pay</option>
                <option value="paytm">Paytm</option>
              </select>
              <IoIosArrowDown className="size-4" />
            </div>
          ) : (
            <Select
              className=" font-normal p-2 z-[99999999] rounded-lg"
              id="bankDropdown"
              onChange={(e) => {
                setUpiProvider(e.setUpiProvider);
                setProviderId(e.setProviderId);
              }}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              options={[
                {
                  setUpiProvider: "phonepe",
                  setProviderId: "@axl",
                  value: "Phone Pe",
                  label: "Phone Pe",
                },
                {
                  setUpiProvider: "gpay",
                  setProviderId: "@okicici",
                  value: "Google Pay",
                  label: "Google Pay",
                },
                {
                  setUpiProvider: "paytm",
                  setProviderId: "@paytm",
                  value: "Paytm",
                  label: "Paytm",
                },
                {
                  setUpiProvider: "other",
                  setProviderId: "",
                  value: "Other",
                  label: "Other",
                },
              ]}
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#E8EBF6",
                  border: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  "::placeholder": {
                    backgroundColor: "#YourDesiredColor",
                    opacity: 1,
                  },
                }),
              }}
            />
          )}
        </div>
      </div>
      {provider !== "other" ? (
        <div className="flex items-center my-8 pl-7 space-x-2">
          <div className="flex justify-center items-center min-w-[110px] h-[40px] py-1.5 shadow-black-shadow rounded-lg">
            <img
              src={icons[provider]}
              className="w-8 object-contain mr-2"
              alt="paymentmode"
            />
          </div>
          <div className="flex flex-col">
            <p className=" text-base text-black font-semibold ">
              {name[provider]}{" "}
            </p>
            <p className=" text-xs font-normal text-[#717171]">
              link your {provider} account
            </p>
          </div>
          {/* <span ref={newRef} className="text-[green]">Upi verified</span> */}
        </div>
      ) : (
        <>
          <div className="flex items-center my-8 pl-7 space-x-2">
            <div className="flex justify-center items-center min-w-[110px] h-[40px] py-1.5 shadow-black-shadow rounded-lg">
              <img
                src={upi}
                className="w-8 object-contain mr-2"
                alt="paymentmode"
              />
            </div>
            <div className="flex flex-col">
              <p className=" text-base text-black font-semibold ">
                UPI Payment
              </p>
              <p className=" text-xs font-normal text-[#717171]">
                link your UPI account
              </p>
            </div>
          </div>
        </>
      )}
      <div className="flex items-center shadow-black-shadow rounded-lg py-2 -mt-2 px-2 mb-4 px-1 ml-5  mr-5 w-auto ">
        <input
          type="text"
          placeholder="Enter VPA ID"
          className="  pl-1 outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-xs"
          ref={vpainputRef}
          onChange={(e) => {
            if (provider !== "other") {
              e.target.value = e.target.value.replace("@", "");
            }
            setVpaId(e.target.value);
            // let vpa = providerId ? vpaId.split("@")[0] + providerId : vpaId;
            // setVpaId(vpa);

            //setVpaId(providerId ? e.target.value + providerId : e.target.value);
          }}
        />
        {provider !== "other" && (
          <select
            value={providerId}
            onChange={(e) => {
              let ids = upiOptions[provider].filter(
                (option) => option === e.target.value
              );

              setProviderId(ids[0]);

              // setProviderIndex(e.target.value)
            }}
            className="w-40 bg-transparent px-5 text-[#717171] text-sm"
            id="vpas"
            name="vpas"
          >
            {upiOptions?.[provider]?.map((option) => (
              <option className="text-[#717171]" value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>

      <button
        ref={mountingRef}
        className="opacity-0 w-0 h-0"
        id="mounting-point"
      ></button>
      <button className="opacity-0 w-0 h-0" id="mounting-point-upiapp"></button>
    </>
  );
};
export default Upi;
