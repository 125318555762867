import { load } from "@cashfreepayments/cashfree-js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import { Cashfree } from "../../payment_gatways/cashfree";

const CardLessEMI = ({
  providerName,
  providerImg,
  providerText,
  providerHeading,
  paymentId,
  payRef,
  collect_request_id,
  childComponentFunctionRef,
  finalAmountWithMDR,
  isBlank,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);

  useEffect(() => {
    payRef.current.disabled = true;
    if (isValidPhoneNumber) payRef.current.disabled = false;
  }, [phoneNumber]);

  useEffect(() => {
    finalAmountWithMDR("cardless emi", providerName);
  }, [providerName]);

  const cashfree = new Cashfree();
  const handleSubmit = async () => {
    if (!isValidPhoneNumber) {
      toast.error("Please enter valid Phone Number");
      return;
    }
    cashfree.initiateCardlessEmi({
      providerName,
      phoneNumber,
      payment_id: paymentId,
      collect_request_id,
      isBlank,
    });
  };

  childComponentFunctionRef.current = handleSubmit;

  return (
    <div className="flex flex-col gap-2 mb-3">
      <div className="flex items-center  ml-3 space-x-2 mt-4">
        <div className="opacity-0 w-0 h-0" id={providerName}></div>
        <div className="flex justify-center items-center w-20 h-10 shadow-black-shadow rounded-lg  ">
          <img src={providerImg} className="w-14 bg-white" alt="paytm" />
        </div>
        <div className="flex flex-col">
          <p className=" text-base text-black font-semibold ">
            {providerHeading}
          </p>
          <p className=" text-[12px] text-[#717171]">
            {providerText || "link your paytm account"}
          </p>
        </div>
      </div>
      <div className="flex items-center bg-white shadow-black-shadow rounded-lg py-3 px-2 px-1 ml-5 mr-5 mt-2 w-auto">
        <div className="text-basic mr-2 ml-1">+91</div>
        <input
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
          value={phoneNumber}
          type="text"
          name="phoneNo"
          id="ph"
          className=" outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-basic "
          placeholder="Phone Number"
          maxLength="10"
          inputMode="numeric"
        />
        {isValidPhoneNumber && <img src={tick} className="m-1" alt="tick" />}
        {!isValidPhoneNumber && (
          <img src={fadedTick} className="m-1" alt="faded" />
        )}
      </div>
    </div>
  );
};

export default CardLessEMI;
