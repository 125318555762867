import { load } from "@cashfreepayments/cashfree-js";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import Select from "react-select";
import jioPay from "../../assets/jioPay.png";
import airtelPay from "../../assets/airtelPay.svg";
import mobi from "../../assets/mobi.svg";
import OlaMoney from "../../assets/Ola_Money.png";
import FreeCharge from "../../assets/freecharge_logo.svg";
import Amazon from "../../assets/amazon-2-logo-svgrepo-com.svg";
import paytm from "../../assets/paytmSvg.svg";
import PhonePe from "../../assets/PhonePe-Logo.wine.svg";

import { Cashfree } from "../../payment_gatways/cashfree";
import { Easebuzz } from "../../payment_gatways/easebuzz";
import { OptMode, WalletEnums } from "../../utils/bankData";
import { GET_PAYMENT_GATEWAY } from "../../services/getPaymentGateway";
const Wallet = (props) => {
  const {
    collect_request_id,
    isBlank,
    walletSet,
    isEasebuzzSchool,
    isBuzz,
    easebuzzPaymentId,
    pgStatus,
  } = props;

  const [phoneNumber, setPhoneNumber] = useState("");
  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);
  const { walletName, walletImg, walletTxt, walletHeading } = props.wallet;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [geteway, setGateway] = useState("");
  // const [services, setServices] = useState("AIRTLM");
  const payRef = props.payRef;

  useEffect(() => {
    props.finalAmountWithMDR("wallet", walletName);
  }, [walletName]);

  useEffect(() => {
    // if(isBuzz === "true"){
    // payRef.current.disabled = false;

    // }
    payRef.current.disabled = true;
    if (isValidPhoneNumber) {
      payRef.current.disabled = false;
    }
  }, [phoneNumber]);

  const options2 = [
    { value: "AIRTLM", label: "Airtelmoney" },
    {
      value: "FCW",
      label: "Freecharge",
    },
    {
      value: "ATM",
      label: "Amazon Pay",
    },
    {
      value: "JIOM",
      label: "Jio Money",
    },
    {
      value: "MOBKWK",
      label: "Mobikwik",
    },
    {
      value: "OLAM",
      label: "OLA Money",
    },
    {
      value: "PAYTM",
      label: "PayTM",
    },
    {
      value: "PHONEPE",
      label: "PhonePe",
    },
  ];

  const walletService = options2.map((walletType) => {
    return walletType.value;
  });

  // const options = walletData.map((service) => {
  //   return { value: service.value, label: service.name };
  // });
  const [services, setServices] = useState(walletService);
  const [selectedService, setSelectedService] = useState("");
  const containerRef = useRef(null);

  const [placeholderService, setPlaceholderService] = useState();
  const cashfree = new Cashfree();
  const easebuzz = new Easebuzz();
  const handleSubmit = async (id) => {
    if (geteway === "EASEBUZZ") {
      easebuzz.initiateWallet({
        walletName: walletName,
        payment_id: props.easebuzzPaymentId,
      });
    } else {
      if (!isValidPhoneNumber) {
        toast.error("Please enter valid Phone Number");
        return;
      } else {
        cashfree.initiateWallet({
          walletName,
          phoneNumber,
          payment_id: props.paymentId,
          collect_request_id,
          isBlank: props.isBlank,
        });
      }
    }
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const handleServiceChange = (selectedServiceValue) => {
    const updatedServices = [
      selectedServiceValue,
      ...services.filter((service) => service !== selectedServiceValue),
    ];
    for (const type of options2) {
      if (type.value === selectedServiceValue) {
        setPlaceholderService(type.label);
        break;
      }
    }
    setSelectedService(selectedServiceValue);
    setServices(updatedServices);
    containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
  };
  const handleImageClick = (service) => {
    handleServiceChange(service);
  };

  const walletList =
    pgStatus.cashfree === true && pgStatus.easebuzz === true
      ? [
          {
            img: PhonePe,
            walletHeading: "PhonePe Wallet",
            walletTxt: "link your PhonePe Wallet",
            walletName: "phonepe",
            value: "Phone Pe",
            label: "Phone Pe",
            cf_value: "phonepe",
            eb_value: "PHONEPE",
            enum: WalletEnums.W_PHONE_PE,
          },
          {
            img: FreeCharge,
            walletHeading: "FreeCharge Wallet",
            walletTxt: "link your FreeCharge Wallet",
            walletName: "freecharge",
            value: "Free Charge",
            label: "Free Charge",
            cf_value: "freecharge",
            eb_value: "",
            enum: WalletEnums.W_FREECHARGE_WALLET,
          },
          {
            img: airtelPay,
            walletHeading: "Airtel Wallet",
            walletTxt: "link your Airtel Wallet",
            walletName: "airtel",
            value: "AIRTLM",
            label: "Airtel Pay",
            cf_value: "airtel",
            eb_value: "AIRTLM",
            enum: WalletEnums.W_AIRTEL_WALLET,
          },
          {
            img: mobi,
            walletHeading: "Mobi Wallet",
            walletTxt: "link your Mobi Wallet",
            walletName: "mobikwik",
            value: "MOBKWK",
            label: "Mobikwik",
            cf_value: "mobikwik",
            eb_value: "MOBKWK",
            enum: WalletEnums.W_MOBIKWIK,
          },
        ]
      : pgStatus.cashfree === true && pgStatus.easebuzz === false
        ? [
            {
              img: PhonePe,
              walletHeading: "PhonePe Wallet",
              walletTxt: "link your PhonePe Wallet",
              walletName: "phonepe",
              value: "Phone Pe",
              label: "Phone Pe",
              cf_value: "phonepe",
              eb_value: "",
              enum: WalletEnums.W_PHONE_PE,
            },
            {
              img: FreeCharge,
              walletHeading: "FreeCharge Wallet",
              walletTxt: "link your FreeCharge Wallet",
              walletName: "freecharge",
              value: "Free Charge",
              label: "Free Charge",
              cf_value: "freecharge",
              eb_value: "",
              enum: WalletEnums.W_FREECHARGE_WALLET,
            },
            {
              img: airtelPay,
              walletHeading: "Airtel Wallet",
              walletTxt: "link your Airtel Wallet",
              walletName: "airtel",
              value: "Airtel Pay",
              label: "Airtel Pay",
              cf_value: "airtel",
              eb_value: "",
              enum: WalletEnums.W_AIRTEL_WALLET,
            },
            {
              img: mobi,
              walletHeading: "Mobi Wallet",
              walletTxt: "link your Mobi Wallet",
              walletName: "mobikwik",
              value: "Mobikwik",
              label: "Mobikwik",
              cf_value: "mobikwik",
              eb_value: "",
              enum: WalletEnums.W_MOBIKWIK,
            },
          ]
        : pgStatus.cashfree === false && pgStatus.easebuzz === true
          ? [
              {
                img: airtelPay,
                walletHeading: "Airtel Wallet",
                walletTxt: "link your Airtel Wallet",
                walletName: "airtel",
                value: "AIRTLM",
                label: "Airtel Pay",
                cf_value: "",
                eb_value: "AIRTLM",
                enum: WalletEnums.W_AIRTEL_WALLET,
              },
              {
                img: mobi,
                walletHeading: "Mobi Wallet",
                walletTxt: "link your Mobi Wallet",
                walletName: "mobikwik",
                value: "MOBKWK",
                label: "Mobikwik",
                cf_value: "",
                eb_value: "MOBKWK",
                enum: WalletEnums.W_MOBIKWIK,
              },
            ]
          : [];

  props.childComponentFunctionRef.current = handleSubmit;
  return (
    <div className="h-full">
      <div className={"p-4 pb-0 " + (menuIsOpen ? "h-80" : "h-auto")}>
        <div className="flex flex-col w-full h-full">
          <label
            className="font-semibold text-[16xp] pl-2"
            htmlFor="bankDropdown"
          >
            Choose Wallet:
          </label>
          <Select
            className=" font-normal p-2 z-[99999999] rounded-lg"
            id="bankDropdown"
            onChange={async (e) => {
              const gatewayName = await GET_PAYMENT_GATEWAY(
                e?.enum,
                OptMode.WALLET
              );
              setGateway(gatewayName);
              const wallet_name =
                gatewayName === "CASHFREE" ? e.cf_value : e.eb_value;

              walletSet(
                e?.img,
                e?.walletHeading,
                e?.walletTxt,
                wallet_name,
                e?.enum
              );
            }}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            options={walletList}
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "#E8EBF6",
                border: "none",
              }),

              input: (provided) => ({
                ...provided,
                backgroundColor: "transparent",
                "::placeholder": {
                  backgroundColor: "#YourDesiredColor",
                  opacity: 1,
                },
              }),
            }}
          />
        </div>
        {/*<div className="flex flex-col w-full ">
          <Select
            className=" font-normal p-2 rounded-lg"
            id="serviceDropdown"
            value={selectedService}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            onChange={(selectedOption) => {
              handleServiceChange(selectedOption.value);
            }}
            options={options2}
            isSearchable
            placeholder={
              placeholderService ? placeholderService : "Search your Wallet"
            }
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "#E8EBF6",
                border: "none",
              }),
              input: (provided) => ({
                ...provided,
                backgroundColor: "transparent",
                "::placeholder": {
                  backgroundColor: "#YourDesiredColor",
                  opacity: 1,
                },
              }),
            }}
          />
        </div>*/}
      </div>
      <div className="flex flex-col gap-2 mb-3">
        <div className="flex items-center bg-white shadow-black-shadow rounded-lg py-3 px-2 px-1 ml-5 mr-5 mt-2 w-auto">
          <div className="text-basic mr-2 ml-1">+91</div>
          <input
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            value={phoneNumber}
            type="text"
            name="phoneNo"
            id="ph"
            className=" outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-basic "
            placeholder="Phone Number"
            maxLength="10"
            inputMode="numeric"
          />
          {isValidPhoneNumber && <img src={tick} className="m-1" alt="tick" />}
          {!isValidPhoneNumber && (
            <img src={fadedTick} className="m-1" alt="faded" />
          )}
        </div>
      </div>

      {/*easebuzz wallete*/}
      {/*<div
        className="flex cursor-pointer items-center h-[80px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7"
        id="pay-later-container"
        ref={containerRef}
      >
        {services.map(
          (service, index) =>
            service !== "Search your Wallet" && (
              <div
                key={index}
                className={`w-[140px] flex-none flex items-center justify-center h-[50px] bg-white shadow-black-shadow rounded-lg pay-later-image ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleImageClick(service)}
              >
                <img
                  className="w-2/3"
                  src={getImageByService(service)}
                  alt={service}
                />
              </div>
            )
        )}
      </div>*/}
    </div>
  );
};
export default Wallet;

function getImageByService(service) {
  switch (service) {
    case "PHONEPE":
      return PhonePe;
    case "PAYTM":
      return paytm;
    case "OLAM":
      return OlaMoney;
    case "ATM":
      return Amazon;
    case "FCW":
      return FreeCharge;
    case "JIOM":
      return jioPay;
    case "AIRTLM":
      return airtelPay;
    case "MOBKWK":
      return mobi;
    default:
      return null;
  }
}
