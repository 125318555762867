let urlParams = new URLSearchParams(window.location.search);
const collect_request_id: any = urlParams.get("collect_request_id");

export const GET_PAYMENT_GATEWAY = async (
  paymentMode: string,
  opt_mode: string
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_PAYMENT_BACKEND}/edviron-pg/school-id?collect_id=${collect_request_id}`
    );
    const schoolId = await res.text();
    if (schoolId) {
      const response = await fetch(
        `${process.env.REACT_APP_MAIN_BACKEND}/optimizer/gateway?paymentMode=${paymentMode}&school_id=${schoolId}&opt_mode=${opt_mode}&collect_id=${collect_request_id}`
      );
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      return await response.text();
    }
  } catch (error) {
    console.log(error);
  }
};
