/* global EasebuzzCheckout */
import { toast } from "react-toastify";
import { PaymentServices } from "../services/paymentService";

const ACTION_LINK = process.env.REACT_APP_EASEBUZZ_ACTION || "";
const EASEBUZZ_MODE = process.env.REACT_APP_EASEBUZZ_MODE;
export class Easebuzz implements PaymentServices {
  initiateUpi(Upi: {
    payment_id: string;
    upi_id: string;
    provider: String;
    collect_request_id: String;
    providerId?: String;
    method?: String;
    amount?: number;
    isBlank?: string;
  }): Promise<String> {
    try {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = Upi.payment_id;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = "UPI";

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "upi_va";
      input3.value = Upi.upi_id;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      document.body.appendChild(form);

      form.submit();
      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  getQrCode(QrCode: {
    qrRef: React.RefObject<HTMLDivElement>;
    paymentId: String;
    collect_request_id: String;
  }): Promise<void> {
    throw new Error("Method not implemented.");
  }

  initiateNetBanking(Netbaking: {
    bankCode: string;
    payment_id: string;
    collect_request_id: String;
    method: String;
    amount: number;
  }): Promise<String> {
    try {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = Netbaking.payment_id;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = "NB";

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "bank_code";
      input3.value = Netbaking.bankCode;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      document.body.appendChild(form);

      form.submit();
      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  initiateWallet(Wallet: {
    walletName: string;
    phoneNumber: String;
    payment_id: string;
    collect_request_id: String;
    amount: number;
    method: String;
  }): Promise<String> {
    try {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = Wallet.payment_id;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = "MW";

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "bank_code";
      input3.value = Wallet.walletName;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      document.body.appendChild(form);

      form.submit();
      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  initiatePaylater(Paylater: {
    payLaterServices: string;
    phoneNumber: String;
    payment_id: string;
    collect_request_id: String;
    amount: number;
    method: String;
  }): Promise<String> {
    try {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = Paylater.payment_id;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = "PL";

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "bank_code";
      input3.value = Paylater.payLaterServices;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      document.body.appendChild(form);

      form.submit();
      return Promise.resolve("");
    } catch (error) {
      throw new Error("Method not implemented.");
    }
  }
  initiateCardlessEmi(CardLessEmi: {
    providerName: String;
    phoneNumber: String;
    payment_id: String;
    collect_request_id: String;
    amount: number;
    method: String;
  }): Promise<String> {
    throw new Error("Method not implemented.");
  }
  initiateCard(Card: {
    payment_id: string;
    card_number: string;
    card_holder_name: string;
    card_cvv: string;
    card_expiry_date: string;
    type: string;
  }): Promise<String> {
    try {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = Card.payment_id;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = Card.type;

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "card_number";
      input3.value = Card.card_number;

      const input4 = document.createElement("input");
      input4.type = "hidden";
      input4.name = "card_holder_name";
      input4.value = Card.card_holder_name;

      const input5 = document.createElement("input");
      input5.type = "hidden";
      input5.name = "card_cvv";
      input5.value = Card.card_cvv;

      const input6 = document.createElement("input");
      input6.type = "hidden";
      input6.name = "card_expiry_date";
      input6.value = Card.card_expiry_date;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      form.appendChild(input4);
      form.appendChild(input5);
      form.appendChild(input6);
      document.body.appendChild(form);

      form.submit();

      // const options = {
      //   access_key: Card.payment_id,
      //   onResponse: (response: any) => {
      //     if (response.error === true) {
      //       toast.error(response.message || "Payment error occurred");
      //     } else if (response.status !== "success") {
      //       toast.error(response.error_Message || "Payment error occurred");
      //     } else if (response.userCancelled) {
      //       toast.error("Payment cancelled!");
      //     }
      //   },
      // };

      // const easebuzzCheckout = new window.EasebuzzCheckout(
      //   Card.payment_id,
      //   EASEBUZZ_MODE
      // );

      // easebuzzCheckout.initiatePayment(options);

      return Promise.resolve("");
    } catch (error: any) {
      if (
        error.message === "EasebuzzCheckout requires 'key' on initialization"
      ) {
        toast.error("No key passed");
      } else {
        toast.error(error.message);
      }
      throw new Error("Method not implemented.");
    }
  }
}
