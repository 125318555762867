import React, { useEffect, useRef, useState } from "react";
import simpl from "../../assets/simpl.png";
import ola from "../../assets/ola.svg";
import zestMoney from "../../assets/zestmoney.svg";
import flexiPay from "../../assets/flexipay.svg";
import kotak from "../../assets/kotak.svg";
import lazyPay from "../../assets/lazypay.svg";
import { load } from "@cashfreepayments/cashfree-js";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import pay_later from "../../assets/payLater.svg";
import { toast } from "react-toastify";
import Select from "react-select";

import { Cashfree } from "../../payment_gatways/cashfree";
import { Easebuzz } from "../../payment_gatways/easebuzz";
import { OptMode } from "../../utils/bankData";
import { GET_PAYMENT_GATEWAY } from "../../services/getPaymentGateway";

const PayLater = ({
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  finalAmountWithMDR,
  isBlank,
  isBuzz,
  easebuzzPaymentId,
  pgStatus,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    payRef.current.disabled = true;
    if (isValidPhoneNumber) payRef.current.disabled = false;
  }, [phoneNumber]);

  // useEffect(() => {
  //   // containerRef.current.scrollTo({ left: 0, behavior: "smooth" });

  //   const handleMouseWheel = (event) => {
  //     const container = document.getElementById("pay-later-container");
  //     if (container) {
  //       container.scrollLeft += event.deltaY;
  //     }
  //   };
  //   document
  //     .getElementById("pay-later-container")
  //     ?.addEventListener("wheel", handleMouseWheel);
  //   return () => {
  //     document
  //       .getElementById("pay-later-container")
  //       ?.removeEventListener("wheel", handleMouseWheel);
  //   };
  // }, []);

  let payLaterServices =
    pgStatus.cashfree === true && pgStatus.easebuzz === true
      ? [
          {
            label: "Simpl",
            value: "Simpl",
            cf_value: "Simpl",
            eb_value: "",
            enum: OptMode.PL_SIMPL_PAY,
          },

          {
            label: "FlexiPay",
            value: "FlexiPay",
            cf_value: "FlexiPay",
            eb_value: "",
            enum: OptMode.PL_FLEXI_PAY,
          },
          {
            label: "Kotak",
            value: "Kotak",
            cf_value: "Kotak",
            eb_value: "",
            enum: OptMode.PL_KOTAK_PAY,
          },
          {
            label: "ZestMoney",
            value: "ZestMoney",
            cf_value: "ZestMoney",
            eb_value: "",
            enum: OptMode.PL_ZEST_MONEY_PAY,
          },
          {
            label: "OlaPostPaid",
            value: "OlaPostPaid",
            cf_value: "OlaPostPaid",
            eb_value: "",
            enum: OptMode.PL_OLA_POST_PAY,
          },
          {
            label: "LazyPay",
            value: "LazyPay",
            cf_value: "LazyPay",
            eb_value: "LazyPay",
            enum: OptMode.PL_LAZY_PAY,
          },
          {
            value: "Tatapl",
            label: "Tata Pay",
            cf_value: "",
            eb_value: "Tatapl",
            enum: OptMode.PL_TATA_PAY,
          },
        ]
      : pgStatus.cashfree === true && pgStatus.easebuzz === false
        ? [
            {
              label: "Simpl",
              value: "Simpl",
              cf_value: "Simpl",
              eb_value: "",
              enum: OptMode.PL_SIMPL_PAY,
            },

            {
              label: "FlexiPay",
              value: "FlexiPay",
              cf_value: "FlexiPay",
              eb_value: "",
              enum: OptMode.PL_FLEXI_PAY,
            },
            {
              label: "Kotak",
              value: "Kotak",
              cf_value: "Kotak",
              eb_value: "",
              enum: OptMode.PL_KOTAK_PAY,
            },
            {
              label: "ZestMoney",
              value: "ZestMoney",
              cf_value: "ZestMoney",
              eb_value: "",
              enum: OptMode.PL_ZEST_MONEY_PAY,
            },
            {
              label: "OlaPostPaid",
              value: "OlaPostPaid",
              cf_value: "OlaPostPaid",
              eb_value: "",
              enum: OptMode.PL_OLA_POST_PAY,
            },
          ]
        : pgStatus.cashfree === false && pgStatus.easebuzz === true
          ? [
              {
                value: "LazyPay",
                label: "Lazy Pay",
                cf_value: "",
                eb_value: "LazyPay",
                enum: OptMode.PL_LAZY_PAY,
              },
              {
                value: "Tatapl",
                label: "Tata Pay",
                cf_value: "",
                eb_value: "Tatapl",
                enum: OptMode.PL_TATA_PAY,
              },
            ]
          : [];

  const options = payLaterServices.map((service) => ({
    value: service,
    label: service,
  }));

  const [services, setServices] = useState(payLaterServices);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [geteway, setGateway] = useState("");
  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);
  const containerRef = useRef(null);

  // const handleServiceChange = (selectedServiceValue) => {
  //   const updatedServices = [
  //     selectedServiceValue,
  //     ...services.filter((service) => service !== selectedServiceValue),
  //   ];

  //   finalAmountWithMDR("paylater", selectedServiceValue);
  //   setServices(updatedServices);
  //   setSelectedService(selectedServiceValue);
  //   setShowPhoneNumberInput(
  //     selectedServiceValue !== "Search your PayLater Service"
  //   );
  //   // containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
  // };

  // const handleImageClick = (service) => {
  //   handleServiceChange(service);
  // };
  const cashfree = new Cashfree();
  const easebuzz = new Easebuzz();
  const handleSubmit = () => {
    const provider = selectedService.toLocaleLowerCase();
    if (geteway === "EASEBUZZ") {
      easebuzz.initiatePaylater({
        payment_id: easebuzzPaymentId,
        payLaterServices: selectedService,
      });
    } else {
      if (isValidPhoneNumber) {
        cashfree.initiatePaylater({
          payLaterServices: provider,
          phoneNumber,
          payment_id: paymentId,
          collect_request_id,
          isBlank,
        });
      }
    }
  };

  childComponentFunctionRef.current = handleSubmit;
  return (
    <div className={`${menuIsOpen ? "h-[400px]" : "h-auto"}  `}>
      <div className="0">
        <div className="flex p-2 mt-4">
          <div className="flex justify-center items-center w-24 h-auto rounded-lg ml-4 mr-4">
            <div className="flex items-center w-auto">
              <img src={pay_later} className="w-14 bg-white" alt="pay" />
              <h6 className="text-xs text-[#717171] font-medium ml-2">
                Pay Later
              </h6>
            </div>
          </div>
          <div className="flex flex-col justify-center items-start mt-1 ">
            <p className="text-base text-black font-semibold">Pay Later</p>
            <p className="text-[12px] text-[#717171]">
              Choose Pay Later Vendor
            </p>
          </div>
        </div>
        <div className="p-4 h-auto max-h-96">
          <div className="flex flex-col w-full ">
            <Select
              className=" font-normal p-2 rounded-lg"
              id="serviceDropdown"
              value={selectedService}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              maxMenuHeight="150px"
              onChange={async (e) => {
                const gatewayName = await GET_PAYMENT_GATEWAY(
                  e?.enum,
                  OptMode.PAY_LATER
                );
                finalAmountWithMDR("paylater", e?.value);
                setGateway(gatewayName);
                const value =
                  gatewayName === "CASHFREE" ? e.cf_value : e.eb_value;
                setSelectedService(value);
                setShowPhoneNumberInput(
                  e?.value !== "Search your PayLater Service"
                );
              }}
              options={payLaterServices}
              isSearchable
              placeholder={
                selectedService
                  ? selectedService
                  : "Search your PayLater Service"
              }
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#E8EBF6",
                  border: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  "::placeholder": {
                    backgroundColor: "#YourDesiredColor",
                    opacity: 1,
                  },
                }),
              }}
            />
          </div>
        </div>
      </div>
      {showPhoneNumberInput && (
        // <div className="flex justify-center mt-4 w-full">
        //   <form
        //     onSubmit={handleSubmit}
        //     className="w-11/12 flex flex-col items-center justify-center"
        //   >
        //     <label className="flex flex-col text-xs">
        //       <div className="flex items-center bg-white shadow-black-shadow rounded-lg py px-2 px-1 ml-5 mr-5 w-auto">
        //         <div className="text-basic mr-2 ml-1">+91</div>
        //         <input
        //           className="border-b-2 ml-2 my-2 text-sm focus:outline-none"
        //           placeholder="Phone Number"
        //           type="text"
        //           value={phoneNumber}
        //           onChange={(e) => {
        //             const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
        //             setPhoneNumber(inputValue.slice(0, 10)); // Limit to 10 characters
        //           }}
        //           maxLength={10}
        //           pattern="[0-9]*" // Allow only digits
        //           title="Please enter only digits"
        //           required
        //         />
        //         {isValidPhoneNumber && (
        //           <img src={tick} className="m-1" alt="tick" />
        //         )}
        //         {!isValidPhoneNumber && (
        //           <img src={fadedTick} className="m-1" alt="faded" />
        //         )}
        //       </div>
        //     </label>
        //     {/* <button id="paylater" className="w-11/12 bg-[#4a2bf8b6] h-10 border mt-1 rounded-lg" type="submit">
        //                     Submit
        //                 </button> */}
        //   </form>
        // </div>
        <div className="flex flex-col gap-2 mb-3">
          <div className="flex items-center bg-white shadow-black-shadow rounded-lg py-3 px-2 px-1 ml-5 mr-5 mt-2 w-auto">
            <div className="text-basic mr-2 ml-1">+91</div>
            <input
              onChange={(event) => {
                const inputValue = event.target.value.replace(/\D/g, "");
                setPhoneNumber(inputValue.slice(0, 10));
              }}
              value={phoneNumber}
              type="text"
              name="phoneNo"
              id="ph"
              className=" outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-basic "
              placeholder="Phone Number"
              maxLength="10"
              inputMode="numeric"
            />
            {isValidPhoneNumber && (
              <img src={tick} className="m-1" alt="tick" />
            )}
            {!isValidPhoneNumber && (
              <img src={fadedTick} className="m-1" alt="faded" />
            )}
          </div>
        </div>
      )}
      <div id="pay-later-mount-point" className={"justify-center mt-8"}>
        <div></div>
      </div>

      {/* {paymentStatus && <div className="payment-status">{paymentStatus}</div>} */}
    </div>
  );
};
export default PayLater;

function getImageByService(service) {
  switch (service) {
    case "Simpl":
      return simpl;
    case "LazyPay":
      return lazyPay;
    case "OlaPostPaid":
      return ola;
    case "Kotak":
      return kotak;
    case "FlexiPay":
      return flexiPay;
    case "ZestMoney":
      return zestMoney;
    default:
      return null;
  }
}
